import heartykitchen from '../locales/heartykitchen.co.uk.json'
import enMessages from '../locales/en_US.json'

const enLangHeartykitchen = {
  messages: {
    ...enMessages,
    ...heartykitchen
  },
  locale: 'en-US',
}
export default enLangHeartykitchen
