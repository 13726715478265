import revealrevenue from '../locales/revealrevenue.com.json';
import enMessages from '../locales/en_US.json';

const enrevealrevenue = {
  messages: {
    ...enMessages,
    ...revealrevenue,
  },
  locale: 'en-US',
};
export default enrevealrevenue;
