import happierleads from '../locales/happierleads.json'
import enMessages from '../locales/en_US.json'

const enLangHappierLeads = {
  messages: {
    ...enMessages,
    ...happierleads
  },
  locale: 'en-US',
}
export default enLangHappierLeads
