import enLangHeartykitchen from './entries/en-US-heartykitchen.js';
import enLangHappierLeads from './entries/en-US-happierleads.js';
import enLangrevealrevenue from './entries/en-US-revealrevenue.js';

import zhLang from './entries/zh-Hans-CN';
import arLang from './entries/ar_SA';
import itLang from './entries/it_IT';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';

const AppLocale = (domain) => {
  if (domain.includes('heartykitchen.co.uk')) {
    return {
      en: { ...enLangHeartykitchen },
      zh: zhLang,
      ar: arLang,
      it: itLang,
      es: esLang,
      fr: frLang,
    };
  } else if (domain.includes('revealrevenue.com')) {
    return {
      en: { ...enLangrevealrevenue },
      zh: zhLang,
      ar: arLang,
      it: itLang,
      es: esLang,
      fr: frLang,
    };
  } else {
    return {
      en: { ...enLangHappierLeads },
      zh: zhLang,
      ar: arLang,
      it: itLang,
      es: esLang,
      fr: frLang,
    };
  }
};

export default AppLocale;
